@import url(https://fonts.googleapis.com/css2?family=Ubuntu&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap'); */
body{
  font-family: 'Ubuntu', sans-serif;    
}
.App {
  text-align: center;
}
html{
  scroll-behavior: smooth;
}
.hide{
  display: none;
}
.scroll-up-btn{
  position: fixed;
  height: 45px;
  width: 42px;
  background-color: #191919;
  right: 30px;
  bottom: 30px;
  border-radius: 6px;
  z-index: 888;
}
.scroll-up-btn .up-btn{
  height: 45px;
  width: 45px;
  transition: all 0.3s ease;
  cursor: pointer;
  color: white;
  font-size: 17px;
  font-weight: 600;
}
.scroll-up-btn .up-btn:hover{
  transform: scale(1.15);
}
/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.main-navbar{
    /* background-color: crimson; */
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    transition: all 0.5s ease;
}
.navbar2{
    background-color: #191919;
    padding: 6px 0;
    z-index: 9999;
}
.main-navbar .navbar{
    width: 80%;
}
.lio{
    color: crimson;
    transition: 1s all;
}
/* .lio2{
    color:white;
} */
.main-navbar .navbar .portfolio{
    float: left;
    font-size: 26px;
    color: white;
    margin: 30px 0px 29px 0;
    font-weight: bolder;
    cursor:pointer;
}
.main-navbar .navbar .MyUl{
    float: right;
    list-style-type: none;
    font-size: 20px;
}
.main-navbar .navbar .MyUl li{
    float: left;
}
.main-navbar .navbar .MyUl li a{
    color: white;
    display: block;
    text-align: center;
    padding: 16px 16px;
    text-decoration: none;
    transition: color 0.3s ease;
}
.main-navbar .navbar .MyUl li a:hover{
    color:crimson;
}
/* .navbar2 .navbar .MyUl li a:hover{
    color:crimson;
} */
.main-navbar .navbar .toggleNavbar{
    display: none;
    float: right;
    float: right;
    font-size: 30px;
    color: white;
    margin: 23px 0 0 0;
}

a:hover {
    cursor: pointer;
}
.to-hide-ul{
    display: block;
    transition: all 0.3s ease;
}


@media only screen and (max-width: 780px) {
    .main-navbar{
        background-color: #191919;
    }
    .lio{
        color:crimson;
    }
    .main-navbar .navbar .toggleNavbar{
        display: block;
    }
    /* .to-hide-ul{
        display: none;
    } */
    .main-navbar .navbar .MyUl{
    display: grid;
    width: 100%;
    }
    .main-navbar .navbar .MyUl li a:hover{
        color:crimson ;
    }
    .to-hide-ul{
        position: fixed;
        bottom: 0;
        right: -100%;
        background: #191919;
        width: 100%;
        height: 90%;
        z-index: 9999;
        transition: all 0.3s ease;
}
}
.showNavbar{
    right:0;
}
.main-home{
    display: flex;
    background: url(/static/media/wood.7e6aef5b.jpg) no-repeat center;
    height: 100vh;
    color: white;
    min-height: 500px;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    background-size:100% 100% ;
    background-repeat: no-repeat;
}
.main-home .home{
    margin-left: 15%;
    transition: all 1.5s ease-in;
}
.main-home .home .text1{
font-size: 27px;
text-align: start;
}
.main-home .home .text2{
font-size: 75px;
font-weight: 600;
}
.main-home .home .text3{
font-size: 30px;
}
.main-home .home .text3 span{
    display: block;
color: crimson;
font-weight: 600;
}
.main-home .home a{
    display: inline-block;
    background: crimson;
    color: white;
    font-size: 25px;
    padding: 12px 36px;
    margin-top: 20px;
    border-radius: 6px;
    border: 2px solid crimson;
    text-decoration: none;
    transition: all 0.3s ease;
}
.main-home .home a:hover{
    color: crimson;
    background: none;
}
@media only screen and (max-width: 701px) {
    .main-home .home{
        margin-left: 3%;
        width:97%;
    }
    .main-home .home .text2{
        font-size: 50px;
    }
        .main-home .home .text3{
        margin-left: 0px;
        width:100%;
    }
    .main-home .home a{
        font-size: 20px;
    }
}

.main-about{
    padding: 100px 0px;
    width: 100%;
}
.about{
    font-family: 'Poppins','sans-serif';
}
.about .tittle{
    position: relative;
    font-family: 'Ubuntu','sans-serif';
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 60px;
    padding-bottom: 20px;
}
.about .tittle::before{
    content: "";
    position: absolute;
    bottom: 0px;
    left: 50%;
    width: 180px;
    height: 3px;
    background-color: black;
    transform: translateX(-50%);
}
.about .tittle::after{
    content: "Who i am";
    position: absolute;
    bottom: -12px;
    left: 50%;
    font-size: 20px;
    color: crimson;
    padding: 5px;
    background-color: white;
    transform: translateX(-50%);
}
.about .left-right-div{
display: flex;
align-items: center;
flex-wrap: wrap;
justify-content: space-between;
}
.about .left-right-div .left{
    width: 45%;
}
.about .left-right-div .left img{
    height: 400px;
    width: 400px;
    border-radius: 6px;
    border: 2px solid crimson;
    object-fit: cover;
}
.about .left-right-div .right{
    width: 50%;
    text-align: start;
    margin-right: 5%;
}
.about .left-right-div .right .text{
    font-size: 25px;
    font-weight: 600;
}
.about .left-right-div .right p{
    text-align: start;
}
.about .left-right-div .right a{
    display: inline-block;
    background: crimson;
    color: white;
    font-size: 20px;
    padding: 10px 30px;
    margin-top: 20px;
    border-radius: 6px;
    border: 2px solid crimson;
    text-decoration: none;
    transition: all 0.3s ease;
}
.about .left-right-div .right a:hover{
    color: crimson;
    background: none;
}
@media only screen and (max-width: 950px) {
    .about .left-right-div .column{
        width: 100%;
    }
    .about .left-right-div .left img{
        height: 300px;
        width: 300px;
    }
    .about .left-right-div .right{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: auto;
        width: 80%;
    }
}
.main-projects{
    background-color:#111;
    color:#fff;
    padding: 50px 0px;
}
.projects{
    font-family: 'Poppins','sans-serif';
}
.projects .tittle{
    position: relative;
    font-family: 'Ubuntu','sans-serif';
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 60px;
    padding-bottom: 20px;
}
.projects .tittle::before{
    content: "";
    position: absolute;
    bottom: 0px;
    left: 50%;
    width: 180px;
    height: 3px;
    background-color: #fff;
    transform: translateX(-50%);
}
.projects .tittle::after{
    content: "what i did";
    position: absolute;
    bottom: -12px;
    left: 50%;
    font-size: 20px;
    color: crimson;
    padding: 5px;
    background-color:#111;
    transform: translateX(-50%);
}
.projects .all-pro-cards{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.projects .all-pro-cards .card{
    width: 40%;
    background-color: #222;
    border-radius: 6px;
    padding: 20px 25px;
    cursor: pointer;
    margin-bottom: 60px;
    transition: all 0.3s ease;
}
.projects .all-pro-cards .card:hover{
    transform:scale(1.05);
    background-color: crimson;
}
.projects .all-pro-cards .card:hover .code-icon{
    color: white;
}
.projects .all-pro-cards .card .code-icon{
    font-size: 50px;
    color: crimson;
    transition: color 0.3s ease;
}
.card .card-title span{
    font-size: 25px;
    font-weight: 500;
    margin:10px 0 7px 0;
}
.projects .all-pro-cards .card:hover a{
    border: 1px solid white;
}
.projects .all-pro-cards .card a{
    text-decoration: none;
    color: white;
    background-color: crimson;
    padding: 1px 20px;
    border-radius: 6px;
    margin-bottom: 10px;
    transition: all 0.3s ease;
}
.projects .all-pro-cards .card a:hover{
    background-color: white;
    color: crimson;
}
.projects .all-pro-cards .card .a-code{
    float:left;
}
.projects .all-pro-cards .card .a-online{
    float: right;
}
@media only screen and (max-width: 1000px) {
    .projects .all-pro-cards .card{
        width: 40%;
        margin-bottom: 60px;
    }
}
@media only screen and (max-width: 601px) {
    .projects .all-pro-cards .card{
        width: 80%;
        margin-bottom: 60px;
    }
}
.main-contact{
    background-color:#111;
    color:#fff;
    font-family: 'Poppins','sans-serif';
    padding: 50px 0px;
}
.contact-content{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.contact .tittle{
    position: relative;
    font-family: 'Ubuntu','sans-serif';
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 60px;
    padding-bottom: 20px;
}
.contact .tittle::before{
    content: "";
    position: absolute;
    bottom: 0px;
    left: 50%;
    width: 180px;
    height: 3px;
    background-color: #fff;
    transform: translateX(-50%);
}
.contact .tittle::after{
    content: "get in touch";
    position: absolute;
    bottom: -12px;
    left: 50%;
    font-size: 20px;
    color: crimson;
    padding: 5px;
    background-color:#111;
    transform: translateX(-50%);
}
.contact .contact-content .column{
    width: calc(50% - 30px);
    margin-bottom: 30px;
}
.contact-content .left .MuiSvgIcon-root{
    height: 50px;
    width: 50px;
    color: crimson;
}
.left .icons{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: start;
    margin-top:10px;
    margin-left: 10%;
    margin-bottom: 20px;
}
.left .icons .info{
margin-left:5%;
text-align: justify;
}
.left .text ,.right .text{
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 10px;
}
.left p{
    text-align: start;
}
.left .icons .info .head{
    font-size: 20px;
    font-weight: 500;
}
.left .icons .info .sub-title{
    color:navajowhite;
}
.contact .contact-content .right{
    text-align: justify;
    margin-right: 10px;
}
.right .fields{
    display: flex;
    margin-bottom: 20px;
}
.right .fields .field, .right .field{
    height: 45px;
    width: 100%;
}
.right .textarea textarea{
height: 80px;
resize: none;
}
.right .fields .field input,.right .field input,.right .textarea textarea{
    height: 100%;
    width: 100%;
    border:1px solid lightgrey;
    border-radius: 6px;
    /* padding: 0 15px; */
    outline: none;
    font-size: 17px;
    font-family: 'Poppins','sans-serif';
}
.right form .name{
margin-right: 10px;
}
.right form .email{
    margin-left: 10px;
}
.right form .subject{
    margin-bottom: 20px;
}
.right form .button{
    margin-top: 20px;
}
.right form .button button{
    float:right;
    background-color: crimson;
    padding: 10px 25px;
    border:1px solid white;
    border-radius: 6px;
    color:white;
    cursor: pointer;
    font-size: 17px;
    font-family: 'Ubuntu','sans-serif';
    transition: all 0.3s ease;
}
.right form .button button:hover{
    background: none;
    border:1px solid crimson;
    color:white;
    transform: scale(1.05);
}
@media only screen and (max-width: 950px) {
    .contact .contact-content .column{
        width: 80%;
    }
}
.main-skill{
    padding: 50px 0px;
    width: 100%;
}
.skill .tittle{
    position: relative;
    font-family: 'Ubuntu','sans-serif';
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 60px;
    padding-bottom: 20px;
}
.skill .tittle::before{
    content: "";
    position: absolute;
    bottom: 0px;
    left: 50%;
    width: 180px;
    height: 3px;
    background-color: black;
    transform: translateX(-50%);
}
.skill .tittle::after{
    content: "what i know";
    position: absolute;
    bottom: -12px;
    left: 50%;
    font-size: 20px;
    color: crimson;
    padding: 5px;
    background-color:white;
    transform: translateX(-50%);
}
.skill .skill-content{
    display: flex;
    flex-wrap: wrap;
    justify-content:space-evenly;
}
.skill .skill-content .column{
    width: calc(50% - 30px);
    /* text-align: start; */
    text-align: justify;

}
.skill .skill-content .left .text{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
}
.skill .skill-content .right .bars{
    margin-bottom: 10px;
    margin-right: 20px;
}
.skill .skill-content .right .info{
    display: flex;
    align-items: center;
    justify-content:space-between;
}
.skill .skill-content .right span{
    font-size: 18px;
    font-weight: 600;
}
.skill .skill-content .right .info{
    margin-bottom: 7px;
}
.skill .skill-content .right .line{
    height: 5px;
    width: 100%;
    background: lightgrey;
    position: relative;
    border-radius: 6px;
}
.skill .skill-content .right .line::before{
    content: "";
    height: 100%;
    background: crimson;
    position: absolute;
    border-radius: 6px; 
}
.skill-content .right .react::before{
 width: 95%;
}
.skill-content .right .express::before{
width: 95%;
}
.skill-content .right .angular::before{
width: 85%;
}
.skill-content .right .php::before{
width: 98%;
}
.skill-content .right .js::before{
width: 98%;
}
.skill-content .right .html::before{
width: 95%;
}
.skill-content .right .css::before{
width: 95%;
}
.skill-content .right .mysql::before{
width: 95%;
}
.skill-content .right .mongo::before{
width: 95%;
}
@media only screen and (max-width: 950px) {
    .skill .skill-content .column{
        width: 70%;
        margin-bottom: 10px;
    }
}
