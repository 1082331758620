.main-skill{
    padding: 50px 0px;
    width: 100%;
}
.skill .tittle{
    position: relative;
    font-family: 'Ubuntu','sans-serif';
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 60px;
    padding-bottom: 20px;
}
.skill .tittle::before{
    content: "";
    position: absolute;
    bottom: 0px;
    left: 50%;
    width: 180px;
    height: 3px;
    background-color: black;
    transform: translateX(-50%);
}
.skill .tittle::after{
    content: "what i know";
    position: absolute;
    bottom: -12px;
    left: 50%;
    font-size: 20px;
    color: crimson;
    padding: 5px;
    background-color:white;
    transform: translateX(-50%);
}
.skill .skill-content{
    display: flex;
    flex-wrap: wrap;
    justify-content:space-evenly;
}
.skill .skill-content .column{
    width: calc(50% - 30px);
    /* text-align: start; */
    text-align: justify;

}
.skill .skill-content .left .text{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
}
.skill .skill-content .right .bars{
    margin-bottom: 10px;
    margin-right: 20px;
}
.skill .skill-content .right .info{
    display: flex;
    align-items: center;
    justify-content:space-between;
}
.skill .skill-content .right span{
    font-size: 18px;
    font-weight: 600;
}
.skill .skill-content .right .info{
    margin-bottom: 7px;
}
.skill .skill-content .right .line{
    height: 5px;
    width: 100%;
    background: lightgrey;
    position: relative;
    border-radius: 6px;
}
.skill .skill-content .right .line::before{
    content: "";
    height: 100%;
    background: crimson;
    position: absolute;
    border-radius: 6px; 
}
.skill-content .right .react::before{
 width: 95%;
}
.skill-content .right .express::before{
width: 95%;
}
.skill-content .right .angular::before{
width: 85%;
}
.skill-content .right .php::before{
width: 98%;
}
.skill-content .right .js::before{
width: 98%;
}
.skill-content .right .html::before{
width: 95%;
}
.skill-content .right .css::before{
width: 95%;
}
.skill-content .right .mysql::before{
width: 95%;
}
.skill-content .right .mongo::before{
width: 95%;
}
@media only screen and (max-width: 950px) {
    .skill .skill-content .column{
        width: 70%;
        margin-bottom: 10px;
    }
}