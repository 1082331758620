.main-contact{
    background-color:#111;
    color:#fff;
    font-family: 'Poppins','sans-serif';
    padding: 50px 0px;
}
.contact-content{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.contact .tittle{
    position: relative;
    font-family: 'Ubuntu','sans-serif';
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 60px;
    padding-bottom: 20px;
}
.contact .tittle::before{
    content: "";
    position: absolute;
    bottom: 0px;
    left: 50%;
    width: 180px;
    height: 3px;
    background-color: #fff;
    transform: translateX(-50%);
}
.contact .tittle::after{
    content: "get in touch";
    position: absolute;
    bottom: -12px;
    left: 50%;
    font-size: 20px;
    color: crimson;
    padding: 5px;
    background-color:#111;
    transform: translateX(-50%);
}
.contact .contact-content .column{
    width: calc(50% - 30px);
    margin-bottom: 30px;
}
.contact-content .left .MuiSvgIcon-root{
    height: 50px;
    width: 50px;
    color: crimson;
}
.left .icons{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: start;
    margin-top:10px;
    margin-left: 10%;
    margin-bottom: 20px;
}
.left .icons .info{
margin-left:5%;
text-align: justify;
}
.left .text ,.right .text{
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 10px;
}
.left p{
    text-align: start;
}
.left .icons .info .head{
    font-size: 20px;
    font-weight: 500;
}
.left .icons .info .sub-title{
    color:navajowhite;
}
.contact .contact-content .right{
    text-align: justify;
    margin-right: 10px;
}
.right .fields{
    display: flex;
    margin-bottom: 20px;
}
.right .fields .field, .right .field{
    height: 45px;
    width: 100%;
}
.right .textarea textarea{
height: 80px;
resize: none;
}
.right .fields .field input,.right .field input,.right .textarea textarea{
    height: 100%;
    width: 100%;
    border:1px solid lightgrey;
    border-radius: 6px;
    /* padding: 0 15px; */
    outline: none;
    font-size: 17px;
    font-family: 'Poppins','sans-serif';
}
.right form .name{
margin-right: 10px;
}
.right form .email{
    margin-left: 10px;
}
.right form .subject{
    margin-bottom: 20px;
}
.right form .button{
    margin-top: 20px;
}
.right form .button button{
    float:right;
    background-color: crimson;
    padding: 10px 25px;
    border:1px solid white;
    border-radius: 6px;
    color:white;
    cursor: pointer;
    font-size: 17px;
    font-family: 'Ubuntu','sans-serif';
    transition: all 0.3s ease;
}
.right form .button button:hover{
    background: none;
    border:1px solid crimson;
    color:white;
    transform: scale(1.05);
}
@media only screen and (max-width: 950px) {
    .contact .contact-content .column{
        width: 80%;
    }
}