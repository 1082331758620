.main-about{
    padding: 100px 0px;
    width: 100%;
}
.about{
    font-family: 'Poppins','sans-serif';
}
.about .tittle{
    position: relative;
    font-family: 'Ubuntu','sans-serif';
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 60px;
    padding-bottom: 20px;
}
.about .tittle::before{
    content: "";
    position: absolute;
    bottom: 0px;
    left: 50%;
    width: 180px;
    height: 3px;
    background-color: black;
    transform: translateX(-50%);
}
.about .tittle::after{
    content: "Who i am";
    position: absolute;
    bottom: -12px;
    left: 50%;
    font-size: 20px;
    color: crimson;
    padding: 5px;
    background-color: white;
    transform: translateX(-50%);
}
.about .left-right-div{
display: flex;
align-items: center;
flex-wrap: wrap;
justify-content: space-between;
}
.about .left-right-div .left{
    width: 45%;
}
.about .left-right-div .left img{
    height: 400px;
    width: 400px;
    border-radius: 6px;
    border: 2px solid crimson;
    object-fit: cover;
}
.about .left-right-div .right{
    width: 50%;
    text-align: start;
    margin-right: 5%;
}
.about .left-right-div .right .text{
    font-size: 25px;
    font-weight: 600;
}
.about .left-right-div .right p{
    text-align: start;
}
.about .left-right-div .right a{
    display: inline-block;
    background: crimson;
    color: white;
    font-size: 20px;
    padding: 10px 30px;
    margin-top: 20px;
    border-radius: 6px;
    border: 2px solid crimson;
    text-decoration: none;
    transition: all 0.3s ease;
}
.about .left-right-div .right a:hover{
    color: crimson;
    background: none;
}
@media only screen and (max-width: 950px) {
    .about .left-right-div .column{
        width: 100%;
    }
    .about .left-right-div .left img{
        height: 300px;
        width: 300px;
    }
    .about .left-right-div .right{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: auto;
        width: 80%;
    }
}