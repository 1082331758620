.main-home{
    display: flex;
    background: url('wood.jpg') no-repeat center;
    height: 100vh;
    color: white;
    min-height: 500px;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    background-size:100% 100% ;
    background-repeat: no-repeat;
}
.main-home .home{
    margin-left: 15%;
    transition: all 1.5s ease-in;
}
.main-home .home .text1{
font-size: 27px;
text-align: start;
}
.main-home .home .text2{
font-size: 75px;
font-weight: 600;
}
.main-home .home .text3{
font-size: 30px;
}
.main-home .home .text3 span{
    display: block;
color: crimson;
font-weight: 600;
}
.main-home .home a{
    display: inline-block;
    background: crimson;
    color: white;
    font-size: 25px;
    padding: 12px 36px;
    margin-top: 20px;
    border-radius: 6px;
    border: 2px solid crimson;
    text-decoration: none;
    transition: all 0.3s ease;
}
.main-home .home a:hover{
    color: crimson;
    background: none;
}
@media only screen and (max-width: 701px) {
    .main-home .home{
        margin-left: 3%;
        width:97%;
    }
    .main-home .home .text2{
        font-size: 50px;
    }
        .main-home .home .text3{
        margin-left: 0px;
        width:100%;
    }
    .main-home .home a{
        font-size: 20px;
    }
}
