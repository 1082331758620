.main-projects{
    background-color:#111;
    color:#fff;
    padding: 50px 0px;
}
.projects{
    font-family: 'Poppins','sans-serif';
}
.projects .tittle{
    position: relative;
    font-family: 'Ubuntu','sans-serif';
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 60px;
    padding-bottom: 20px;
}
.projects .tittle::before{
    content: "";
    position: absolute;
    bottom: 0px;
    left: 50%;
    width: 180px;
    height: 3px;
    background-color: #fff;
    transform: translateX(-50%);
}
.projects .tittle::after{
    content: "what i did";
    position: absolute;
    bottom: -12px;
    left: 50%;
    font-size: 20px;
    color: crimson;
    padding: 5px;
    background-color:#111;
    transform: translateX(-50%);
}
.projects .all-pro-cards{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.projects .all-pro-cards .card{
    width: 40%;
    background-color: #222;
    border-radius: 6px;
    padding: 20px 25px;
    cursor: pointer;
    margin-bottom: 60px;
    transition: all 0.3s ease;
}
.projects .all-pro-cards .card:hover{
    transform:scale(1.05);
    background-color: crimson;
}
.projects .all-pro-cards .card:hover .code-icon{
    color: white;
}
.projects .all-pro-cards .card .code-icon{
    font-size: 50px;
    color: crimson;
    transition: color 0.3s ease;
}
.card .card-title span{
    font-size: 25px;
    font-weight: 500;
    margin:10px 0 7px 0;
}
.projects .all-pro-cards .card:hover a{
    border: 1px solid white;
}
.projects .all-pro-cards .card a{
    text-decoration: none;
    color: white;
    background-color: crimson;
    padding: 1px 20px;
    border-radius: 6px;
    margin-bottom: 10px;
    transition: all 0.3s ease;
}
.projects .all-pro-cards .card a:hover{
    background-color: white;
    color: crimson;
}
.projects .all-pro-cards .card .a-code{
    float:left;
}
.projects .all-pro-cards .card .a-online{
    float: right;
}
@media only screen and (max-width: 1000px) {
    .projects .all-pro-cards .card{
        width: 40%;
        margin-bottom: 60px;
    }
}
@media only screen and (max-width: 601px) {
    .projects .all-pro-cards .card{
        width: 80%;
        margin-bottom: 60px;
    }
}