.main-navbar{
    /* background-color: crimson; */
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    transition: all 0.5s ease;
}
.navbar2{
    background-color: #191919;
    padding: 6px 0;
    z-index: 9999;
}
.main-navbar .navbar{
    width: 80%;
}
.lio{
    color: crimson;
    transition: 1s all;
}
/* .lio2{
    color:white;
} */
.main-navbar .navbar .portfolio{
    float: left;
    font-size: 26px;
    color: white;
    margin: 30px 0px 29px 0;
    font-weight: bolder;
    cursor:pointer;
}
.main-navbar .navbar .MyUl{
    float: right;
    list-style-type: none;
    font-size: 20px;
}
.main-navbar .navbar .MyUl li{
    float: left;
}
.main-navbar .navbar .MyUl li a{
    color: white;
    display: block;
    text-align: center;
    padding: 16px 16px;
    text-decoration: none;
    transition: color 0.3s ease;
}
.main-navbar .navbar .MyUl li a:hover{
    color:crimson;
}
/* .navbar2 .navbar .MyUl li a:hover{
    color:crimson;
} */
.main-navbar .navbar .toggleNavbar{
    display: none;
    float: right;
    float: right;
    font-size: 30px;
    color: white;
    margin: 23px 0 0 0;
}

a:hover {
    cursor: pointer;
}
.to-hide-ul{
    display: block;
    transition: all 0.3s ease;
}


@media only screen and (max-width: 780px) {
    .main-navbar{
        background-color: #191919;
    }
    .lio{
        color:crimson;
    }
    .main-navbar .navbar .toggleNavbar{
        display: block;
    }
    /* .to-hide-ul{
        display: none;
    } */
    .main-navbar .navbar .MyUl{
    display: grid;
    width: 100%;
    }
    .main-navbar .navbar .MyUl li a:hover{
        color:crimson ;
    }
    .to-hide-ul{
        position: fixed;
        bottom: 0;
        right: -100%;
        background: #191919;
        width: 100%;
        height: 90%;
        z-index: 9999;
        transition: all 0.3s ease;
}
}
.showNavbar{
    right:0;
}